<template>
  <div
    v-if="isVisible"
    class="delete-or-archive"
    :style="{
      maxWidth: `${maxWidth}px`,
    }"
  >
    <v-btn
      text
      class="delete-or-archive__btn"
      :color="buttonColor"
      :disabled="loading"
      :loading="loading"
      @click="toggle"
    >
      {{ this.currentAction }}
    </v-btn>

    <ConfirmModal
      :value="showModal"
      :title="confirmationTitle"
      :message="confirmationMessage"
      :confirm-color="confirmColor"
      :confirm-text="confirmText"
      :cancel-color="cancelColor"
      :cancel-text="cancelText"
      :loading="loading"
      @cancel="close"
      @confirm="submit"
      @input="toggle"
    ></ConfirmModal>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Delete or restore field
 *
 * NOTE: Restore not yet implemented!
 * ==================================================================================
 **/
import { Model } from 'vue-api-query'
import { capitalize } from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {
    ConfirmModal,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    model: {
      type: Model,
      default: () => {
        return null
      },
    },

    redirect: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: 'Delete?',
    },

    cancelColor: {
      type: String,
      default: 'red',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    confirmColor: {
      type: String,
      default: 'green',
    },

    confirmText: {
      type: String,
      default: 'Confirm',
    },

    maxWidth: {
      type: Number,
      default: 400,
    },

    restore: {
      type: Function,
      default: () => null,
    },

    hasRestore: {
      type: Boolean,
      default: false,
    },

    // Option url delete
    deletePath: {
      type: String,
      default: null,
    },
    backNavigate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  computed: {
    nameCapitalized() {
      return capitalize(this.name)
    },

    currentAction() {
      return this.isArchived ? 'Restore' : 'Delete'
    },

    confirmationTitle() {
      return `${this.currentAction}?`
    },

    confirmationMessage() {
      return `Are you sure you want to ${this.currentAction.toLowerCase()} this ${
        this.name
      }?`
    },

    buttonColor() {
      return this.isArchived ? 'success' : 'red'
    },

    action() {
      return this.isArchived ? this.activate : this.remove
    },

    isArchived() {
      return this.model ? !!this.model.deleted_at : false
    },

    isVisible() {
      if (!this.model) return false
      return this.isArchived ? this.hasRestore : true
    },
  },

  methods: {
    toggle(show = null) {
      this.showModal =
        show === null || typeof show !== 'boolean' ? !this.showModal : show
    },

    open() {
      this.toggle(true)
    },

    close() {
      this.toggle(false)
    },

    async submit() {
      if (this.loading) return
      this.loading = true

      await this.action()

      this.loading = false
      this.close()
    },

    async remove() {
      try {
        if (this.deletePath) {
          await this.$api.delete(this.deletePath)
        } else {
          await this.model.delete()
        }

        this.showSnackbar(`${this.nameCapitalized} deleted successfully!`)
        this.$emit('deleted')
        if (this.backNavigate) {
          window.history.back()
        } else {
          this.$router.replace(this.redirect)
        }
      } catch (error) {
        this.showSnackbar(this.getErrorMessage(error), false)
      }
    },

    async activate() {
      if (!this.restore || typeof this.restore !== 'function') return

      await this.restore({
        id: this.model.id,
      })
        .then(() => {
          this.showSnackbar(`${this.nameCapitalized} restored successfully!`)

          this.$router.replace(this.redirect)
        })
        .catch((error) => {
          this.showSnackbar(this.getErrorMessage(error), false)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.delete-or-archive {
  text-align: right;

  &__btn {
    font-weight: bold;
  }
}
</style>
